<script>
import TableauCommun from '@/components/shared/tableauCommun'
import PageHeader from '@/components/shared/pageHeader'
import RetirerEnseignant from '@/components/dialogs/retirerEnseignant'

import ContextBearer from '@/mixins/contextBearer'

export default {
  name: 'EnseignantDetail',
  mixins: [ContextBearer],
  components: {
    RetirerEnseignant,
    TableauCommun,
    PageHeader
  },
  data () {
    return {
      loading: true,
      loadingClasse: true,
      classeTotal: 0,
      page: 1,
      limit: 25,
      listClasse: [],
      enseignant: null,
      credits: [],
      enseignantFetchOnce: false,
      contextBearer: null
    }
  },
  apollo: {
    enseignant: {
      query: require('@/graphql/queries/v2/salleClasseUsager.gql'),
      variables () {
        return {
          id: this.$route.params.id
        }
      },
      update (data) {
        this.loading = false
        return data.salleClasseUsager
      },
      result () {
        this.onEnseignantFetched()
      },
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    onEnseignantFetched () {
      if (this.enseignantFetchOnce) {
        return
      } else {
        this.enseignantFetchOnce = true
      }

      this.fetchSearchSalleClasse()
    },
    async fetchSearchSalleClasse () {
      const { username, accessCode } = this.enseignant.info
      this.contextBearer = await this.generateBearerFromUsernameAndAccessCode(username, accessCode)

      const { data } = await this.$apollo.query({
        query: require('@/graphql/queries/v2/searchSalleClasses.gql'),
        variables: {
          contextBearer: this.contextBearer,
          page: this.page,
          limit: this.limit
        },
        fetchPolicy: 'no-cache'
      })

      if (this.page === 1) {
        this.listClasse = data.searchSalleClasses.resultat
        this.classeTotal = data.searchSalleClasses.total
      } else {
        this.listClasse.push(...data.searchSalleClasses.resultat)
      }

      this.classes = data
      this.loadingClasse = false
    },
    openDialogSupprEnseignant () {
      const enseignant = this.$route.params.id
      if (!enseignant) {
        return
      }
      this.$store.commit('Dialog/setData', { enseignant })
      this.$store.commit('App/setGlobalDialog', { value: true, context: 'retirerEnseignant' })
    },
    ajoutCredit () {
      console.log('ici la')
    }
  }
}
</script>

<template lang="pug">
.enseignant-detail
  page-header(
    :title="enseignant ? `${enseignant.info.nom} ${enseignant.info.prenom}` : null"
    :backButtonLabel="$t('menu.mes-enseignants')"
    :backButtonTo="{ name: 'enseignants___' + $i18n.locale, params: { lang: $i18n.locale } }"
  )
        //- v-chip.primary.lighten-5.secondary--text.mr2.mt1(small)
        //-   span(v-if='enseignant') nombre de classe
        //-   v-skeleton-loader(v-else, type='button')
        //- v-chip.primary.lighten-5.secondary--text.mr2.mt1(small)
        //-   span(v-if='enseignant') nom deleve a gere
        //-   v-skeleton-loader(v-else, type='button')
  .page-actions
    v-card(color='primary', v-ripple, :disabled='loading')
      v-card-title {{ $t('action.connexion-enseignant') }}
    v-card(color='primary', v-ripple, :disabled='loading' @click="openDialogSupprEnseignant")
      v-card-title {{ $t('action.suppression-enseignant') }}
  .page__main-content.mt3
    section.large-section
      .section-subtitle__header
        h2.section-subtitle {{ $t('produit.credits') }}
      v-sheet.section-card(:color='$store.state.Preferences.darkMode ? "light" : "white"')
        template(v-if='credits.length > 0')
          credit-disponible(:produit='produit', :paquets='commande.nuagePaquets', :enseignant='true')
        template(v-else)
          v-card.tc
            v-card-title {{ $t('enseignant.aucun-credit') }}
            v-card-actions
              v-btn(color='primary', style='margin: auto;', @click='ajoutCredit') {{ $t('enseignant.ajout-credit') }}
    section.small-section
      .section-subtitle__header
        h2.section-subtitle {{ $t('partage.classe-enseignant') }}
        v-btn(small, outlined, rounded, @click='', disabled) {{ $t('action.creer-classe') }}
      v-card.section-card(:color='$store.state.Preferences.darkMode ? "light" : "white"')
        tableau-commun(
          :loading='loadingClasse',
          :nbTotal='classeTotal',
          :items='listClasse',
          :headers='[{ text: $t("classe.nom-classe"), value: "titre" }, { text: $t("classe.nombre-eleves"), value: "sallesUsagers.total" }, { text: "", value: "actions", sortable: false }]',
          routeName='classeDetail'
          :noDataText='$t("classe.no-class-found")'
        )
</template>
<style lang='sass'>
.page__main-content
  display: grid
  grid-template-columns: 50% 50%
  grid-template-rows: 1fr
  gap: 11px 11px
  grid-auto-flow: row
</style>
