export default {
  methods: {
    async generateBearerFromUsernameAndAccessCode (username, accessCode) {
      // If bearer have already been fetched and stored, return it
      const bearersFromStore = this.$store.state?.User?.bearers
      if (bearersFromStore && bearersFromStore[username]) {
        return bearersFromStore[username]
      }

      // Fetch bearer and store it
      const { data } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/identification.gql'),
        variables: { username, accessCode }
      })
      const bearer = data.identificationSalleClasse
      this.$store.commit('User/addBearer', { username, bearer })
      return bearer
    }
  }
}
